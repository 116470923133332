import React, { Component } from 'react';
import emailjs from 'emailjs-com';
import './contactform.css';

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      message: "",
      sending: false,
      success: false,
      error: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { firstName, lastName, email, message } = this.state;

    this.setState({ sending: true });

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        {
          from_name: `${firstName} ${lastName}`,
          from_email: email,
          message_html: message,
        },
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(() => {
        this.setState({
          firstName: '',
          lastName: '',
          email: '',
          message: '',
          sending: false,
          success: true,
          error: false,
        });
      })
      .catch(() => {
        this.setState({
          sending: false,
          success: false,
          error: true,
        });
      });
  }

  render() {
    const { firstName, lastName, email, message, sending, success, error } =
      this.state;

    return (
      <div className='contact-form-container'>
        <form className='contact-form' onSubmit={this.handleSubmit}>
        <h1>Kontaktujte nás</h1>
          <label htmlFor='firstName'>Jméno</label>
          <input
            type='text'
            name='firstName'
            value={firstName}
            onChange={this.handleChange}
            required
          />

          <label htmlFor='lastName'>Příjmení</label>
          <input
            type='text'
            name='lastName'
            value={lastName}
            onChange={this.handleChange}
            required
          />

          <label htmlFor='email'>Email</label>
          <input
            type='email'
            name='email'
            value={email}
            onChange={this.handleChange}
            required
          />

          <label htmlFor='message'>Zpráva</label>
          <textarea
            name='message'
            value={message}
            onChange={this.handleChange}
            required
          />

          <button className='button__default' type='submit' disabled={sending}>
            {sending ? 'Odesílání...' : 'Odeslat'}
          </button>
          {success && (
          <div className='flash-message success-message'>
            <span>Vaše zpráva byla úspěšně odeslána. V nejbližší době se Vám ozveme. :)</span>
          </div>
        )}
        {error && (
          <div className='flash-message error-message'>
           <span>Vaši zprávu se nepodařilo odeslat. Zkuste to prosím později.</span>
          </div>
        )}
        </form>
      </div>
      
    );
  }
}

export default ContactForm;
