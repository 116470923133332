import React, { useState } from 'react';
import './navbar.css';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../assets/logo.webp';

const Menu = () => (
    <>
    <p><a href='/'>Domů</a></p>
    <p><a href='o-nas'>O nás</a></p>
    <p><a href='kontakt'>Kontakt</a></p>
    </>
)

const Navbar = () => {
    const [ToggleMenu, setToggleMenu] = useState(false);
    return (
      <div className="navbar">
        <div className="navbar-links">
          <div className="navbar-links_logo">
            <a href='/'><img src={logo} alt="logo" /></a>
          </div>
          <div className="navbar-links-items">
            <Menu />
          </div>
          <div className="navbar-button">
            <a href="/kurzy">
              <button type="button__default">Kurzy</button>
            </a>
          </div>
          <div className="navbar-menu">
            {ToggleMenu ? (
              <RiCloseLine
                color="121212"
                size={27}
                onClick={() => setToggleMenu(false)}
              />
            ) : (
              <RiMenu3Line
                color="121212"
                size={27}
                onClick={() => setToggleMenu(true)}
              />
            )}
            {ToggleMenu && (
              <div className="navbar-menu-container scale-up-center">
                <div className="navbar-menu-container-links">
                  <Menu />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
}

export default Navbar
