import React from 'react';
import {useParams } from 'react-router-dom';
import ContactForm from './ContactForm';
import Footer from './Footer';
import Navbar from './Navbar';
import './coursedetails.css';

const courses = [
  {
    id: 'individual',
    name: 'Individuální kurzy',
    subtext: 'Příprava individuálních témat na míru dle znalostí a potřeb studenta, konzultace, studijní materiály a cvičení. Naší specializací je právo, ekonomie, obchod a zdravotnictví.',
    description: 'Počet hodin dle potřeby studenta\nMožnost skupinové výuky (do 5 lidí)\nNabídka firemních kurzů\nVýuka probíhá online',
    price: '800'
  },
  {
    id: 'school',
    name: 'Příprava na maturitní a státní zkoušku',
    subtext:'Kurz sestává z vypracování maturitních otázek, doplnění gramatických znalostí, tréninku ústního projevu. V ceně je zahrnuta kontrola a korektura písemných prací.',
    description: 'Počet hodin dle potřeby studenta\nMožnost skupinové výuky (do 5 lidí)\nVýuka probíhá online',
    price:'700',
 
  },
  {
    id: 'a2',
    name: 'Course 3',
    subtext:'V ceně každého kurzu zaměřeného na složení mezinárodního certifikátu u Goethe Institutu je zahrnuta kontrola zadaných úkolů, gramatických cvičení, písemných prací, konzultace mimo hodiny.',
    description: '15 hodin (možno individuálně přizpůsobit)\nMožnost skupinové výuky (do 3 osob)\nVýuka probíhá online',
    price:'600',
  },
  {
    id: 'b1',
    name: 'Příprava na certifikát B1',
    subtext:'V ceně každého kurzu zaměřeného na složení mezinárodního certifikátu u Goethe Institutu je zahrnuta kontrola zadaných úkolů, gramatických cvičení, písemných prací, konzultace mimo hodiny.',
    description: '20 hodin (možno individuálně přizpůsobit)\nMožnost skupinové výuky (do 3 osob)\nVýuka probíhá online',
    price:'800  ',
  },
  {
    id: 'b2',
    name: 'Příprava na certifikát B2',
    subtext:'V ceně každého kurzu zaměřeného na složení mezinárodního certifikátu u Goethe Institutu je zahrnuta kontrola zadaných úkolů, gramatických cvičení, písemných prací, konzultace mimo hodiny.',
    description: '25 hodin (možno individuálně přizpůsobit)\nMožnost skupinové výuky (do 3 osob)\nVýuka probíhá online',
    price:'800',
  },
  {
    id: 'c1',
    name: 'Příprava na certifikát C1',
    subtext:'V ceně každého kurzu zaměřeného na složení mezinárodního certifikátu u Goethe Institutu je zahrnuta kontrola zadaných úkolů, gramatických cvičení, písemných prací, konzultace mimo hodiny.',
    description: '30 hodin (možno individuálně přizpůsobit)\nMožnost skupinové výuky (do 3 osob)\nVýuka probíhá online',
    price:'800',
  },
  {
    id: 'c2',
    name: 'Příprava na certifikát C2',
    subtext:'V ceně každého kurzu zaměřeného na složení mezinárodního certifikátu u Goethe Institutu je zahrnuta kontrola zadaných úkolů, gramatických cvičení, písemných prací, konzultace mimo hodiny.',
    description: '35 hodin (možno individuálně přizpůsobit)\nMožnost skupinové výuky (do 3 osob)\nVýuka probíhá online',
    price:'800',
  },
];

function CourseDetails() {
  const { id } = useParams();
  const course = courses.find((c) => c.id === id);

  return (
    <div>
      <Navbar />
      <div className="container">
      <div className="detail section__padding">
        <h1>{course.name}</h1>
        <p className="subtext">{course.subtext}</p>
        <ul>
          {course.description.split("\n").map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <h3>Cena</h3>
        <span>{course.price} Kč/h</span>
        <p className="sale">Skupinová sleva možná</p>
        <ContactForm came_from={id} />
        <Footer />
      </div>
    </div>
    </div>
  );
}

export default CourseDetails;
