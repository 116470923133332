import React from "react";
import Navbar from "./components/Navbar";
import ContactForm from "./components/ContactForm";
import './contact.css';




function Contact () {
return (
  <div>
    <Navbar />
    <div className="section__padding container">
      <h1>Spojte se s námi</h1>
      <div>
        <ContactForm />
      </div>
    </div>
    <div className="box_container">
        <h1>Provozovatel</h1>
        <p>Fronde s.r.o.</p>
        <p>IČ: 11851945</p>
        <p>Spisová značka: C 355357/MSPH Městský soud v Praze</p>
        <h1>Kontakt</h1>
        <p>skola@fronde.cz</p>
        <p>777007042</p>
      </div>
    
  </div>
);

}
export default Contact;