import React from "react";
import './header.css';
import teacher from "../assets/teacher.png"
import { Link } from "react-router-dom";

const Header = () => {
    return (
      <div className="header section__padding" id="domu">
        <div className="header-content">
          <h1 className="gradient__text">Škola němčiny, co má smysl.</h1>
          <p>
            S námi začnete mít němčinu rádi. Nepřemlouváme vás, přesvědčte se
            sami.
          </p>
          <Link to="/kurzy">
            <button type="button" className="button__default">
              Nabídka kurzů
            </button>
          </Link>
        </div>
        <div className="header-image">
          <img src={teacher} alt="teacher"></img>
        </div>
      </div>
    );
}

export default Header