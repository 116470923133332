import React from "react";
import Navbar from "./components/Navbar";
import './About.css';

function About () {
return (
  <div>
    <Navbar />
    <div className="container">
    <div className="top section__padding">
      <img src="https://avvhkklgrlzoqutlptcz.supabase.co/storage/v1/object/public/fronde/tereza.webp?t=2023-04-21T10%3A01%3A02.608Z" alt="" />
      <div className="text">
        <h2>Tereza Krajčová</h2>
        <p>
          Jmenuji se Tereza Krajčová a pracuji jako lektorka, tlumočnice a
          překladatelka. Německému jazyku se aktivně věnuji od roku 1987.
          Studium jsem završila složením obecné mezinárodní zkoušky a zkoušky z
          tlumočnictví a překladatelství úrovně C2. Po dvouletém pobytu v
          Německu jsem jsem dlouhodobě pracovala pro německé společnosti,
          překladatelské agentury, advokátní kanceláře. Vždy jsem však
          inklinovala k pedagogické činnosti, ve které vidím největší smysl.
        </p>
      </div>
    </div>
    <div className="content section__padding">
    <h2>Proč zrovna Fronde?</h2>
    <p>
      Pokud se nechcete spokojit s tím, že vám Němci přibližně rozumí, jste tu
      správně. Nabízím živou výuku, individuální přístup a výukové materiály,
      které se za léta osvědčily. Gramatiku vysvětlím jednoduše a uceleně, vše
      si snadno zapamatujete a po absolvování kurzu si budete jistí v jejím
      používání. Osobně ručím za úspěšné absolvování mezinárodních zkoušek.
      Existuje celá řada soukromých i veřejných kurzů, které absolvujete a máte
      dobrý pocit, že jste se zlepšili. Z mých osobních zkušeností a ze
      zkušeností mých studentů však vyplývá, že kromě dobrého pocitu zůstáváte
      stále na stejném místě. Současné moderní učebnice němčiny jsou většinou
      zmatečné, se spoustou komplikovaných frází, aniž byste věděli, proč a kdy
      je používat. Dnešním trendem je “škola hrou”, ale pokud nežijete v dané
      zemi a neslyšíte jazyk každý den, tato metoda nefunguje. V době pandemie
      jsem začala vyučovat online a podařilo se mi vytvořit velmi efektivní
      systém, díky kterému studenti šetří svůj čas, aniž by výuka ztrácela na
      kvalitě.
    </p>
  </div>
  </div>
  </div>
);

}
export default About;