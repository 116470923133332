import React from 'react';
import './App.css';
import Home from './Home';
import About from './About';
import Contact from './Contact';
import CourseList from './components/CourseList';
import CourseDetails from './components/CourseDetails';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/kurzy" element={<CourseList />} />
        <Route path="/o-nas" element={<About />} />
        <Route path="/kontakt" element={<Contact />} />
        <Route path="/kurzy/:id" element={<CourseDetails />} />
      </Routes>
    </Router>
  );
  
  }
  export default App;