import React from 'react';
import { Link } from 'react-router-dom';
import './courselist.css';
import Navbar from './Navbar';

const courses = [
  {
    id: 'individual',
    name: 'Individuální kurzy dle osobní potřeby',
    description: 'počet hodin dle dohody\nZaměření na právo, ekonomii, obchod zdravotnictví a diplomacii\nPříprava a zpracování individuálních témat\nStudijní materiály a cvičení',
  },
  {
    id: 'school',
    name: 'Příprava na maturitní a státní zkoušku',
    description: 'Počet hodin dle dohody\nVypracování maturitních otázek\nDoplnění gramatických znalostí\nTrénink ústního projevu\nKorektura písemných prací2',
  },
  {
    id: 'a2',
    name: 'Příprava na certifikát A2',
    description: '15 hodin\nGramatické cvičení\nPísemné práce\nÚkoly\nKonzultace',
  },
  {
    id: 'b1',
    name: 'Příprava na certifikát B1',
    description: '20 hodin\nGramatické cvičení\nPísemné práce\nÚkoly\nKonzultace',
  },
  {
    id: 'b2',
    name: 'Příprava na certifikát B2',
    description: '25 hodin\nGramatické cvičení\nPísemné práce\nÚkoly\nKonzultace',
  },
  {
    id: 'c1',
    name: 'Příprava na certifikát C1',
    description: '30 hodin\nGramatické cvičení\nPísemné práce\nÚkoly\nKonzultace',
  },
  {
    id: 'c2',
    name: 'Příprava na certifikát C2',
    description: '35 hodin\nGramatické cvičení\nPísemné práce\nÚkoly\nKonzultace',
  },
  
];

function CourseList() {
  return (
    <div>
      <Navbar />
      <div className="courses_list section__padding">
        {courses.map((course) => (
          <div className="courses_list-item" key={course.id}>
            <h1>{course.name}</h1>
            <ul>
              {course.description.split("\n").map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
            <Link to={`/kurzy/${course.id}`}>
              <button className="button__default">Více</button>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CourseList;
