import React from "react";
import Navbar from "./components/Navbar";
import Header from "./components/Header";
import Fields from "./components/Fields";
import Reviews from "./components/Reviews";
import Experience from "./components/Experience";
import Footer from "./components/Footer";

function Home () {
    return (
      <>
        <Navbar />
        <Header />
        <Fields />
        <Reviews />
        <Experience />
        <Footer />
      </>
    );
}

export default Home;