import React from "react";
import "./reviews.css";
import User from "../assets/user.png";

const Reviews = () => {
return (
  <div className="wrapper section__padding">
    <h1>Naše reference</h1>
    <div className="reviews">
      <div className="reviews_box">
        <p className="reviews_box_title">
          "S Terezou vás bude němčina opravdu moc bavit. Zaprvé je Tereza
          lektorkou s mnohaletou praxí. Zadruhé dokáže velmi dobře přizpůsobit
          výuku individuálním požadavkům a potřebám studenta. A zatřetí umí být
          velice vtipná, takže lekce němčiny s ní uteče jako voda a vy se proto
          rozhodně nebudete nudit.”
        </p>
        <div className="reviews_box_person">
          <img src={User} alt="" />
          <p className="reviews_box_person_name">Michal Stupavský</p>
          <p>Investiční stratég</p>
        </div>
      </div>
      <div className="reviews_box">
        <p className="reviews_box_title">
          “S výukou jsem velmi spokojený. Vyhovuje mi systematický a pečlivý
          přístup lektorky, který je zároveň i lidský. Rozhodně doporučuji..”
        </p>
        <div className="reviews_box_person">
          <img src={User} alt="" />
          <p className="reviews_box_person_name">Ing. Jiří Šír</p>
          <p>Náměstek ministra</p>
        </div>
      </div>
      <div className="reviews_box">
        <p className="reviews_box_title">
          „Hledala jsem, až jsem našla“ – lépe nedokážu vyjádřit, jak moc si
          cením pedagogického a lidského přístupu paní lektorky Terezy Krajčové.
          Ví přesně jaká úskalí nám Čechům němčina nachystala a umí je nejen
          skvěle vysvětlit, ale i usnadnit studentům se s nimi poprat. Díky ní
          jsem u němčiny vydržela a učení si užívám.
        </p>
        <div className="reviews_box_person">
          <img src={User} alt="" />
          <p className="reviews_box_person_name">RNDr. Lída Lhotská, PhD.</p>
          <p>Studentka jazykové školy</p>
        </div>
      </div>
    </div>
  </div>
);
}

export default Reviews;