import React from "react";
import "./experience.css";
import Bulb from "../assets/bulb.png";
import People from "../assets/people.png";
import Throphy from "../assets/throphy.png";


const Experience = () => {
    return (
      <div className="experience">
        <div className="experience_items section__padding">
          <div>
            <img src={Bulb} alt="light_bulb" />
            <h1>200+</h1>
            <p>Úspěšných studentů</p>
          </div>
          <div>
            <img src={People} alt="light_bulb" />
            <h1>100%</h1>
            <p>Úspěšnost u zkoušek</p>
          </div>
          <div>
            <img src={Throphy} alt="light_bulb" />
            <h1>35 let</h1>
            <p>Zkušeností</p>
          </div>
        </div>
        <div className="cta">
          <h1>Máte dotaz? Neváhejte nás kontaktovat.</h1>
          <button>Kontaktuje nás</button>
        </div>
      </div>
    );
}

export default Experience;