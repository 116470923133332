import React from "react";
import './fields.css';
import idea from '../assets/idea.png';
import pen from '../assets/pen.png';
import research from '../assets/research.png';
import support from '../assets/support.png';

const Fields = () => {
    return (
      <div className="services">
        <div className="services-info">
          <h1>Zaměření výuky</h1>
          <p>
            Všechny hodiny jsou připraveny na míru, s maximální péčí a
            odborností. Klademe důraz na srozumitelnou, jednoduchou a pro
            každého pochopitelnou výuku, bez ohledu na to, jestli jste zrakový
            nebo poslechový typ, pokročilí nebo začátečníci. S každou další
            hodinou získáte větší jistotu a sebevědomí.
          </p>
        </div>

        <div className="services-items">
          <div>
            <img src={idea} alt="idea"></img>
            <p>PRÁVO A EKONOMIE</p>
          </div>
          <div>
            <img src={pen} alt="pen"></img>
            <p>PŘÍPRAVA NA CERTIFIKÁTY</p>
          </div>
          <div>
            <img src={research} alt="research"></img>
            <p>EKONOMIE A OBCHOD</p>
          </div>
          <div>
            <img src={support} alt="support"></img>
            <p>PRÁVO A ZDRAVOTNICTVÍ</p>
          </div>
        </div>
      </div>
    );
}

export default Fields;