import React from "react";
import './footer.css';


const Footer = () => {
    
    return(
        <div className="footer section__padding">
            <div>
                <p>Copyright &copy; Fronde s.r.o. 2023. Všechna práva vyhrazena. Made with <span role="img" aria-label="love">&#x1F49C;</span> by <a href='https://splx.cz/'>SPLX.cz</a></p>
            </div>

        </div>
    )
}

export default Footer;